export const routingTable = {
    order: 'objednavka',
    blog: 'blog',
    error: 'stranka-nenalezena',
    noQueryResult: 'error/querysearch',
    additional: {
        aboutUs: 'o-firme',
        contact: 'kontakt',
        pos: 'obchody-arte',
        posQ: 'obchody-arte:q',
        posSingle: 'obchody-arte/:shop'
    },
    checkout: {
        base: 'kosik',
        account: 'uzivatel',
        login: 'prihlaseni',
        summary: 'shrnuti',
        final: 'dokonceni',
        payment: 'platba'
    },
    customer: {
        base: 'klient',
        login: 'prihlaseni',
        register: 'registrace',
        passwordRecovery: 'obnoveni-hesla',
        passwordRecoveryNewPassword: 'obnoveni-hesla/:token',
        account: {
            base: 'ucet',
            myData: 'udaje',
            myCoupons: 'kupony',
            favourite: 'oblibene',
            reviews: 'recenze',
            lastWatched: 'nedavno-prohlizene',
            orders: 'objednavky'
        },
    },
    shop: {
        listing: 'produkty',
        compare: 'porovnat',
        product: 'produkt',
        listingFriendly: [],
    },
}